if (/MSIE \d|Trident.*rv:/.test(window.navigator.userAgent)) {
	if (window && window.Element) {
		window.Element.prototype.remove = function () {
			this.parentElement.removeChild(this);
		};
	}
	if (window && window.NodeList) {
		// @ts-ignore
		window.NodeList.prototype.remove = window.HTMLCollection.prototype.remove = function () {
			for (let i = this.length - 1; i >= 0; i--) {
				if (this[i] && this[i].parentElement) {
					this[i].parentElement.removeChild(this[i]);
				}
			}
		};
	}
}
